<template>
   <div class="">
     <headers />
    <div class="mb" style="padding:15px;padding-top: 70px !important;">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h1 class="page-title">{{lan.corp_recommend_add}}</h1>
        </div>
      </div>
    </div>

      <div class="row">
      
        <div class="tab-content" style="width:100%;background:#fff">
    
             <div class="embed-responsive-16by9 tab-pane show active tc2" id="tc2" >
                <div class="col-12">
                  <div class="card-box">
                    <corp-create-ai-info :apiUrl="url" returnPage="corp_need_info" paramKey="id" />
                  </div>
                </div>
            </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>

import CorpCreateAiInfo from '@/components/CorpCreateAiInfo.vue';
import Headers from '@/components/Headers.vue'
import { inject } from 'vue';


export default {
  name: 'corp_need_edit2',
  components: {
    Headers,CorpCreateAiInfo
  },
  data() {
    return {
      curindex:'tc1',
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
      url: '/v1/corp_need2/',
    }
  },
  methods: {
    tab(id) {
      this.curindex = id
    }
  },
  created() {
   
  }
}
</script>