<template>
  <!-- Start Content-->
  <div>
    <router-link
      :to="{ name: returnPage }"
      class="btn btn-primary waves-effect waves-light btn-back-fixed"
    >
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>

    <transition name="fade" mode="out-in">
      <div class="modal" tabindex="-1" role="dialog" v-show="gridModal">
        <div class="modal-bg" @click="CancelGridReport"></div>
        <div class="modal-dialog grid-modal-dialog responsive-table-plugin w-100">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">{{$language('データ選択')}}</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                @click="CancelGridReport"
              >×</button>
            </div>
            <div class="modal-body table-responsive">
              <table class="table table-striped text-right">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{{lan.corpName}}</th>
                    <th>{{lan.corpNum}}</th>
                    <th>{{lan.address}}</th>
                    <th>gps</th>
                    <th>{{lan.phone}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(gridData, num) in gridList"
                    :key="num"
                    @click="SelectGridDataRow(num)"
                  >
                    <td>{{ num + 1 }}</td>
                    <td>{{ gridData.Corp_Name }}</td>
                    <td>{{ gridData.Corp_Num }}</td>
                    <td>{{ gridData.address }}</td>
                    <td>{{ gridData.gps }}</td>
                    <td>{{ gridData.TEL }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="CancelGridReport">{{$language('キャンセル')}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr">
      <Form>
        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div id="rootwizard">
          <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
            <li class="nav-item" data-target-form="#accountForm" @click="cur_step=1">
              <a
                href="javascript:void(0)"
                data-toggle="tab"
                :class="(cur_step==2 ? '':'active')+' nav-link rounded-0 pt-2 pb-2'"
              >
                <i class="mdi mdi-account-circle mr-1"></i>
                <span class="d-none d-sm-inline">{{lan.cusotmer_info_input}}</span>
              </a>
            </li>
            <li class="nav-item" data-target-form="#profileForm" @click="cur_step=2">
              <a
                href="javascript:void(0)"
                data-toggle="tab"
                :class="(cur_step==1 ? '':'active')+' nav-link rounded-0 pt-2 pb-2'"
              >
                <i class="mdi mdi-face-profile mr-1"></i>
                <span class="d-none d-sm-inline">{{lan.cusotmer_need_input}}</span>
              </a>
            </li>
          </ul>

          <div class="tab-content mb-0 b-0 pt-0">
            <div :class="(cur_step==2 ? '':'active')+'tab-pane'" id="first">
              <form id="accountForm" method="post" action="#" class="form-horizontal">
                <div class="row">
                  <div class="col-12">
                    <div class="card-box" style="margin-bottom: 0;padding: 0;">
                      <h2 class="header-title mb-3">{{lan.cusotmer_info_input}}</h2>
                      <div class="row mb-4" style="margin-bottom: auto !important;">
                        <div class="col-10">
                          <div class="row">
                            <div class="form-group col-12 col-md-6">
                              <div
                                style="align-items: flex-end;display: flex;justify-content: space-between;"
                              >
                                <div style="flex:1">
                                  <label class="col-form-label pt-0 pb-0">{{lan.CorpName}}</label>
                                  <Field
                                    name="lender_corpName"
                                    :class="'form-control col-10 col-md-12'"
                                    v-model="info.corpName"
                                  />
                                  <ErrorMessage class="invalid-feedback" name="lender_corpName" />
                                </div>
                                <!-- ssssss -->
                                <!-- <div
                                class="d-flex justify-content-center col-10 col-md-12 pt-2"
                                v-if="gridLoading"
                                >-->
                                <div style="width: 150px;text-align: center;" v-if="gridLoading">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>

                                <!--  -->
                                <div v-else style="width: 150px;">
                                  <button
                                    type="button"
                                    @click="GridSearch"
                                    class="btn btn-primary ml-sm-4"
                                  >{{$language('データ取得')}}</button>
                                </div>
                                <!-- ssssss -->
                              </div>
                              <p class="mb-1">
                                <code v-if="gridResult">{{ gridResult }}</code>
                              </p>
                            </div>

                            <div class="form-group col-12 col-md-6">
                              <label class="col-form-label pt-0 pb-0">{{lan.CorpNum}}</label>
                              <Field
                                name="lender_corpNum"
                                :class="'form-control col-10 col-md-12'"
                                v-model="info.CorpNum"
                              />
                              <ErrorMessage class="invalid-feedback" name="lender_corpNum" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>

                <div class="row">
                  <div class="form-group col-12 col-md-6">
                    <label class="col-form-label pt-0 pb-0">{{lan.manager}}</label>
                    <Field
                      name="lender_manager"
                      :class="'form-control col-10 col-md-12'"
                      v-model="info.manager"
                    />
                    <ErrorMessage class="invalid-feedback" name="lender_manager" />
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label class="col-form-label pt-0 pb-0">{{lan.email}}</label>
                    <Field
                      name="lender_email"
                      :class="'form-control col-10 col-md-12'"
                      v-model="info.email"
                    />
                    <ErrorMessage class="invalid-feedback" name="lender_email" />
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label class="col-form-label pt-0 pb-0">{{lan.telephone}}</label>
                    <Field
                      name="lender_telephone"
                      type="tel"
                      :class="'form-control col-10 col-md-12'"
                      v-model="info.telephone"
                    />
                    <ErrorMessage class="invalid-feedback" name="lender_telephone" />
                  </div>
                </div>

                <!-- end col -->

                <!-- end row -->
              </form>
            </div>

            <div :class="(cur_step!=2 ? '':'active')+'tab-pane'" id="second">
              <form id="profileForm" method="post" action="#" class="form-horizontal">
                <div class="row">
                  <div class="col-12">
                    <div class="card-box">
                      <h2 class="header-title mb-3">{{lan.cusotmer_info_input}}</h2>
                      <div class="row mb-4">
                        <div class="col-10">
                          <div class="row" style="flex-direction: column;">
                            <div
                              class="form-group col-12 col-md-6 i-address-arr"
                              v-for="(item,index) in info.addressData"
                              :key="index"
                            >
                              <div>
                                  <div>{{lan.Address}}: {{item.address}};</div>
                                  <div>{{$language('距离')}}: {{item.distance}}m</div>
                              </div>
                              <div class="i-delete-btn" @click="delete_address(index)">{{$language('删除')}}</div>
                            </div>
                          </div>
                          <div class="row google-map-box">
                            <div class="col-4">
                              <div>
                                <div class="lang" v-html="lan.address"></div>:
                                <br />
                                <input
                                  class="form-control"
                                  id="address"
                                  style="width:200px;display:inline-block;flex: none !important;"
                                  type="text"
                                  placeholder="Search"
                                  aria-label="Search"
                                  v-model="address"
                                />

                                <div style="width: 100%;height:10px"></div>
                                <div class="lang" v-html="lan.distance"></div>: (m)
                                <div class="help">
                                  <ctooltip
                                    :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
                                    :align="'right'"
                                    :help_text="lan.help_cbreai_distance"
                                  />
                                </div>
                                <input
                                  class="form-control mr-1"
                                  style="width:90px !important; margin-left: 23px;display:inline-block; flex: none !important;"
                                  type="text"
                                  placeholder="Distance"
                                  aria-label="Distance"
                                  v-model="distance"
                                />

                                <div style="width: 100%;height:10px"></div>
                                <span class="search-btn">
                                  <button
                                    type="button"
                                    class="btn waves-effect waves-light btn-black"
                                    @click="search"
                                  >
                                    <i class="fa fa-search mr-1"></i>
                                    <!-- <div class="lang" v-html="lan.search"></div> -->
                                    <div class="lang">{{$language('添加')}}</div>
                                  </button>
                                </span>
                              </div>
                              <!-- <div class="lang" v-html="lan.address"></div>:
                              <input
                                class="form-control"
                                id="address"
                                style="width:200px;display:inline-block;flex: none !important;"
                                type="text"
                                placeholder="Search"
                                aria-label="Search"
                                v-model="address"
                              />-->
                            </div>

                            <div class="col-4" style="max-width: none;flex: 1;">
                              <div id="googleMap" class="google"></div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-12 col-md-6">
                              <label
                                class="col-form-label pt-0 pb-0"
                                style="width: 100%;"
                              >{{lan.area}}</label>
                              <Field
                                name="areaFrom"
                                :class="'form-control col-2'"
                                type="number"
                                v-model="info.areaFrom"
                                style="min-width: 100px;"
                              />
                              &nbsp;&nbsp;{{lan.to}}&nbsp;&nbsp;
                              <Field
                                name="areaTo"
                                type="number"
                                :class="'form-control col-2'"
                                v-model="info.areaTo"
                                style="min-width: 100px;"
                              />
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label class="col-form-label pt-0 pb-0">{{lan.move_date}}</label>
                              <select
                                class="form-control mt-0"
                                style="width: 250px;"
                                v-model="info.moveingType"
                              >
                                <option
                                  v-for="(item,index) in moveingTypeList"
                                  :key="index"
                                  :value="item.value"
                                  :selected="{true : index == 1}"
                                >{{item.text}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </form>
            </div>

            <ul class="list-inline wizard mb-0">
              <li class="previous list-inline-item disabled">
                <a
                  href="javascript: void(0);"
                  class="btn btn-secondary btn-black"
                  @click="prev_step()"
                >{{lan.prev}}</a>
              </li>
              <li class="next list-inline-item float-right">
                <a
                  href="javascript: void(0);"
                  class="btn btn-secondary btn-black"
                  @click="onSubmit()"
                >{{cur_text}}</a>
              </li>
            </ul>
          </div>
          <!-- tab-content -->
        </div>

        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
    <div v-else>
      <code>{{$language('データ取得に失敗しました')}}</code>
    </div>
  </div>
  <!-- container -->
</template>

<script>
import axios from "axios";
import router from "@/router";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { inject } from "vue";

const { currentRoute } = router;

export default {
  name: "CorpCreateAi",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    returnPage: String,
  },
  data() {

    return {
      schema:'',
      jsonObj: [Object],
      jsonErr: false,
      isLoading: true,
      cur_building: null,
      isValid: true,
      isEdit: false,
      saveAlert: 0, // 保存結果
      saveLoading: false,
      reportId: "",
      deleteModal: false,
      deleteAlert: "",
      downloadAlert: "",
      publishState: false,
      gridLoading: false,
      gridResult: "",

      sortData: [],
      gridList: [],
      gridCount: 0,
      gridModal: false,

      userName: document.cookie.replace(
        /(?:(?:^|.*;\s*)name\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      ),
      name_search: "",
      location_search: "",
      series: [],
      BuildingID: "", //物件ID

      center: { lat: Number, lng: Number },
      address: "",
      oldAddressData: [],
      areaFrom: "",
      areaTo: "",
      moveingType: 1,
      moveingTypeList: [
        { value: "1", text:this.$language('一ヶ月以内') },
        { value: "2", text:this.$language('一季度以内') },
        { value: "3", text:this.$language('半年内') },
        { value: "4", text:this.$language('１年')},
        { value: "5", text:this.$language('3年') },
        { value: "6", text:this.$language('5年') },
      ],

      distance: 500,
      sort: "kz",
      industry: "",
      cur_text: "",
      cur_step: 1,
      CorpNum: "",
      CorpName: "",
      type:
        currentRoute.value.query.type == undefined
          ? "company"
          : currentRoute.value.query.type,
      mapList: [],
      reload: 0,
      cad: "",
      map: null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker: {},
          InfoWindow: {},
          Circle: {},
        },
      },
      markers: [],
      infowindow: [],
      curindex: "tc1",
      tableHeaders: [],

      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      cur_lan: "jp",
      ccenter: "",
      radius: 0,
      info: {
        corpName: "",
        CorpNum: "",
        telephone: "",
        manager: "",
        email: localStorage.getItem("email"),
        areaFrom: "",
        areaTo: "",
        addressData: [],
        moveingType: "1",
      },
      isPopup: false,
    };
  },
  mounted() {
    this.schema = yup.object({
      lender_corpName: yup
        .string()
        .nullable()
        .required(this.$language('必須入力です。'))
        .max(255, '255'+this.$language('文字以内で入力してください。')),
      lender_corpNum: yup
        .string()
        .nullable()
        .required(this.$language('必須入力です。'))
        .max(1024, "1024"+this.$language('文字以内で入力してください。')),
      lender_manage: yup
        .string()
        .nullable()
        .required(this.$language('必須入力です。'))
        .max(1000, "1000"+this.$language('文字以内で入力してください。')),
      lender_telephone: yup
        .string()
        .nullable()
        .required(this.$language('必須入力です。'))
        .matches(/^[a-zA-Z0-9-+]*$/,this.$language('半角英数字で入力してください。'))
        .max(100, "100"+this.$language('文字以内で入力してください。')),
      lender_email: yup
        .string()
        .nullable()
        .required(this.$language('必須入力です。'))
        .max(500, "500"+this.$language('文字以内で入力してください。')),
      lender_area_from: yup
        .string()
        .nullable()
        .matches(/^[a-zA-Z0-9-/]*$/, "半角英数字で入力してください。")
        .max(10, "10"+this.$language('文字以内で入力してください。')),
      lender_area_to: yup
        .string()
        .nullable()
        .matches(/^[a-zA-Z0-9-/]*$/,this.$language('半角英数字で入力してください。'))
        .max(10, "10"+this.$language('文字以内で入力してください。')),
    });
    this.cur_text = this.lan.next;
    if (currentRoute.value.query.dataId) {
      this.reportId = currentRoute.value.query.dataId;
    } else {
      this.isLoading = false;
    }
    this.sortData = [
      { name: "kz", value: this.lan.firms_tend_to_enhance },
      { name: "kz", value: this.lan.firms_tend_to_downsize },
    ];
    var that = this;
    if (this.reportId > 0) {
      // edit

      (this.isEdit = true),
        axios
          .get(
            process.env.VUE_APP_API2_URL_BASE +
              "/getCorpNeedInfo?id=" +
              this.reportId
          )
          .then((response) => {
            
            (this.info.corpName = response.data.data.corpName),
              (this.info.CorpNum = response.data.data.corpNumber),
              (this.info.telephone = response.data.data.telephone),
              (this.info.manager = response.data.data.manager),
              // this.info.manager: "",
              (this.info.email = response.data.data.email);
            (this.info.areaFrom = response.data.data.areaFrom),
              (this.info.areaTo = response.data.data.areaTo);
            this.info.addressData = JSON.parse(response.data.data.addressData);
            this.info.moveingType = response.data.data.moveDateType;

            this.isLoading = false;
            this.loadGoogleMapsScript().then(() => {
              this.google = window.google;
              this.autoaddress();
              this.initializeMap(that);
            });
            //公開ステータス確認
            if (response.data.publish === 1) {
              this.publishState = true;
            } else {
              this.publishState = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error), (this.jsonErr = true);
          });
    } else {
      this.loadGoogleMapsScript().then(() => {
        this.google = window.google;
        this.autoaddress();
        this.initializeMap(that);
      });
    }
  },
  methods: {
    delete_address(index) {
      this.info.addressData.splice(index, 1);
    },
    prev_step() {
      this.cur_step = 1;
      this.cur_text = this.lan.next;
    },
    CancelGridReport: function () {
      this.gridModal = false;
    },
    //grid検索
    GridSearch: function () {
      // 入力チェック
      if (this.info.corpName) {
        (this.gridLoading = true),
          axios
            .get(process.env.VUE_APP_API2_URL_BASE + "/getNameByCompany", {
              params: {
                name: this.info.corpName, //物件名
                type: "company",
              },
            })
            .then((response) => {
              this.gridLoading = false;
              let result = response.data.data;
              this.gridList = result;
              if (result.length > 1) {
                this.gridModal = true;
                return;
              }
              this.SelectGridDataRow(0);
            })
            // 0件
            .catch(() => {
              (this.gridResult =this.$language('一致するデータが見つかりません。')),
                (this.gridLoading = false);
            });
      } else {
        this.gridResult =this.$language('物件名と所在地を入力してください。');
      }
    },
    //Grid選択モーダルのデータ選択
    SelectGridDataRow: function (num) {
      this.CancelGridReport();
      this.info.corpName = this.gridList[num].Corp_Name;
      this.info.CorpNum = this.gridList[num].Corp_Num;
      this.info.telephone = this.gridList[num].TEL;
      this.info.addressData = [
        {
          address: this.gridList[num].address,
          distance: 2000,
          gps: this.gridList[num].gps,
        },
      ];
    },
    onSubmit() {
      if (this.cur_step == 1) {
        this.cur_step = 2;
        this.cur_text = this.lan.save;
        return false;
      }
      this.saveLoading = true;
      this.saveAlert = 0;
      this.saveData();
    },

    saveData() {
      let params = {
        id: this.reportId,
        CorpNum: this.info.CorpNum,
        CorpName: this.info.corpName,
        addressData: this.info.addressData,
        email: this.info.email,
        manager: this.info.manager,
        telephone: this.info.telephone,
        areaFrom: this.info.areaFrom,
        areaTo: this.info.areaTo,
        moveingType: this.info.moveingType,
      };

      axios
        .post(process.env.VUE_APP_API2_URL_BASE + "/CorpNeedInfoSave", {
          ...params,
        })
        .then((response) => {
       
          this.reportId = response.data.id;
          this.$router.push("/corp_need_info");
          this.saveAlert = 1;
          this.saveLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.saveAlert = 9;
          this.saveLoading = false;
        });
      this.isEdit = true;
      this.$nextTick(() => {
        window.parent.postMessage(["loaded"], "*");
      });
    },

    Report: function () {
      this.saveLoading = true;
      this.saveAlert = 0;
      if (this.reportId) {
        axios
          .put(process.env.VUE_APP_API2_URL_BASE + "/CorpNeedSave", {
            data: {
              id: this.reportId,
              name: localStorage.getItem("name"),
              CorpNum: this.CorpNum,
              CorpName: this.CorpName,
              address: this.address,
              distance: this.distance,
              industry: this.industry,
              sort: this.sort,
            },
          })
          .then(() => {
            this.saveAlert = 1;
            this.$nextTick(() => {
              this.saveLoading = false;
            });
          })
          .catch((error) => {
            console.log(error);
            this.saveAlert = 9;
            this.saveLoading = false;
          });
        this.$nextTick(() => {
          window.parent.postMessage(["loaded"], "*");
        });
      } else {
        axios
          .post(process.env.VUE_APP_API2_URL_BASE + "/CorpNeedSave", {
            data: {
              name: localStorage.getItem("name"),
              CorpNum: this.CorpNum,
              CorpName: this.CorpName,
              address: this.address,
              distance: this.distance,
              industry: this.industry,
              sort: this.sort,
            },
          })
          .then((response) => {
            this.reportId = response.data.id;
            this.$router.push("edit/" + this.reportId);
            this.saveAlert = 1;
            this.saveLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.saveAlert = 9;
            this.saveLoading = false;
          });
        this.isEdit = true;
        this.$nextTick(() => {
          window.parent.postMessage(["loaded"], "*");
        });
      }
    },
    PrivateReport: function () {
      this.summary.publish = 0;
      this.publishState = false;
    },
    PublishReport: function () {
      this.summary.publish = 1;
      this.publishState = true;
    },

    initdraw(map) {
      console.log("ss");
      var _that = this;
      var drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
        },

        circleOptions: {
          strokeColor: "#003f2d",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#003f2d",
          fillOpacity: 0.35,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (event) {
          if (event.type == "circle") {
            _that.ccenter = JSON.parse(
              JSON.stringify(event.overlay.getCenter())
            );
            _that.radius = parseInt(event.overlay.getRadius());
            _that.distance = _that.radius;
            console.log(_that.radius + "," + _that.distance + "<1>");
            new window.google.maps.Geocoder().geocode(
              { location: event.overlay.getCenter() },
              function (results, status) {
                if (status === "OK" && results[0]) {
                  _that.address = results[0].formatted_address;
                  document.querySelector("#address").value = _that.address;
                  _that.radius = parseInt(event.overlay.getRadius());
                  _that.distance = _that.radius;
                  console.log(_that.radius + "," + _that.distance + "<1.5>");
                  _that.search();
                  window.google.maps.event.addListener(
                    event.overlay,
                    "radius_changed",
                    function () {
                      _that.radius = parseInt(event.overlay.getRadius());
                      _that.distance = _that.radius;
                      console.log(_that.radius + "," + _that.distance + "<2>");
                      _that.search();
                    }
                  );
                }
              }
            );
          }
        }
      );
    },
    autoaddress() {
      let address1Field;
      address1Field = document.querySelector("#address");
      new this.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["jp"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      address1Field.focus();
    },

    search: function () {
      var that = this;
      // this.address = document.querySelector("#address").value;
      // console.log(this.ccenter);
      // console.log(this.radius + "," + this.distance + "<3>");
      this.initializeMap(that);
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=places,drawing,geometry&v=weekly&channel=2";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 10000);
      });
    },
    initializeMap(_that) {
      const mapContainer = _that.$el.querySelector("#googleMap");

      const { Map, MapTypeId, Circle } = _that.google.maps;
      const Geocoder = new _that.google.maps.Geocoder();

      this.cad = "";
      // map create
      var zoom = 7;
      if (_that.distance > 3000000) {
        zoom = 3;
      } else if (_that.distance > 2000000) {
        zoom = 4;
      } else if (_that.distance > 1000000) {
        zoom = 5;
      } else if (_that.distance > 500000) {
        zoom = 6;
      } else if (_that.distance > 200000) {
        zoom = 7;
      } else if (_that.distance > 100000) {
        zoom = 8;
      } else if (_that.distance > 50000) {
        zoom = 9;
      } else if (_that.distance > 20000) {
        zoom = 10;
      } else if (_that.distance > 10000) {
        zoom = 11;
      } else if (_that.distance > 5000) {
        zoom = 12;
      } else if (_that.distance > 2000) {
        zoom = 13;
      } else if (_that.distance > 1000) {
        zoom = 14;
      } else if (_that.distance > 500) {
        zoom = 15;
      } else if (_that.distance > 200) {
        zoom = 16;
      } else if (_that.distance > 100) {
        zoom = 17;
      } else if (_that.distance > 50) {
        zoom = 18;
      } else if (_that.distance > 20) {
        zoom = 19;
      } else {
        zoom = 20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

      let addr = "";
      if (_that.address == "") {
        addr = "";
      } else {
        addr = _that.address;
      }
  
      new Geocoder.geocode({ address: addr }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;

          let center = {
            lat: parseFloat(locate.lat()),
            lng: parseFloat(locate.lng()),
          };
          console.log(center);
          if (center != null && center.lat != null) {
            _that.ccenter = center;
            let flag = true;
            _that.info.addressData.forEach((item) => {
              if (item.address == addr || addr == '') {
                flag = false;
              }
            });
            if (flag) {
              _that.info.addressData.push({
                address: addr,
                distance: _that.distance,
                gps: center.lat + "," + center.lat,
              });
            }
          }
          // set locate
          _that.map.setCenter(center);
          var cc = new Circle({
            strokeColor: "#003f2d",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#003f2d",
            fillOpacity: 0.35,
            map: _that.map,
            center: center,
            editable: true,
            radius: parseInt(_that.distance),
          });
          _that.google.maps.event.addListener(
            cc,
            "radius_changed",
            function () {
              _that.radius = parseInt(cc.getRadius());
              _that.distance = _that.radius;
              _that.search();
            }
          );
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },

    gotowindow(i) {
      var _that = this;
      if (this.cad != "") {
        _that.infowindow[this.cad.replace("k", "")].close();
      }
      this.cad = "k" + i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map: _that.map,
        shouldFocus: false,
      });
    },
  },
  watch: {
    cur_step(newValue) {
      if (newValue == 1) {
        this.cur_text = this.lan.next;
        return;
      }
      this.cur_text = this.lan.save;
    },
  },
};
</script>


<style lang="scss" scoped>

.btn-black{
  background-color: #013f2e;
  color: #fff;
}
.google-map-box{
  margin-bottom: 30px;
}
.col-2 {
  float: initial !important;
  display: inline-block;
}
.disabled .select2-selection--single {
  background: #dfdfdf !important;
}
.valid-feedback,
.invalid-feedback {
  display: block !important;
}

.btn-back-fixed {
  position: fixed;
  right: 0;
  top: 16px;
  z-index: 255;
}

.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  width: 30.5%;
}

.modal {
  display: block;
}

.modal-bg {
  background: rgba(50, 58, 70, 0.5);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.grid-modal-dialog {
  max-width: 1000px;
  white-space: nowrap;
  & tbody tr {
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #343a40;
      background-color: #dae6ec;
      border-color: #d1e0e8;
    }
  }
}

@media screen and (max-width: 768px) {
  .grid-modal-dialog {
    margin: {
      left: auto;
      right: auto;
      top: 2.5rem;
    }
    max-width: 80vw;
  }
}
.google {
  height: 550px;
  width: 100%;
  float: right;
}
table td {
  max-width: 200px;
}
.types {
  margin-left: 10px;
}
.help {
  position: relative;
  margin-left: 10px;
  display: inline-block;
}
.help-box {
  margin-top: 10%;
  width: 60%;
}
.col-4 {
  float: left;
}
.i-delete-btn {
  margin-left: 50px;
  padding: 5px;
  background-color: #013f2e;
  border-radius: 8px;
  color: #fff;
  width: 50px;
  text-align: center;
}
.i-address-arr {
  display: flex;
  line-height: 20px;
  align-items: center;
}
</style>
